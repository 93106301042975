import React from 'react'
import styled from 'styled-components'

import typographySizes from 'src/assets/styles/typographySizes.js'
import colors from 'src/assets/styles/colors.js'
import mediaQuery from 'src/assets/styles/mediaQuery.js'
import distances from 'src/assets/styles/distances.js'

import introImg from 'src/assets/images/intro-img.png'
import smalHex from 'src/assets/svg/intro-small-hex.svg'
import biHex from 'src/assets/svg/intro-big-hex.svg'

import MainWrapper from 'src/components/global/MainWrapper.js'
import Button from 'src/components/global/Button.js'
// import menuPdf from 'src/assets/KIMI_PINK_menu.pdf'

const StyledMainWrapper = styled(MainWrapper)`
  background-color: ${colors.bright};
  padding-bottom: ${distances.s}rem;
  @media (max-width: ${mediaQuery.tablet}) {
    padding-bottom: ${distances.xs}rem;
  }
`

const ImgTextWrapper = styled.section`
  display: flex;
  justify-content: space-between;
  border-radius: 40rem;
  background-color: ${colors.brightGreen};
  height: 800rem;
  width: 100%;
  overflow: hidden;
  @media (max-width: ${mediaQuery.tablet}) {
    flex-direction: column;
    height: auto;
    border-radius: 9rem;
  }
`

const ImageWrapper = styled.div`
  width: 50%;
  height: 100%;
  background-image: url(${introImg});
  background-size: cover;
  background-position: center;
  @media (max-width: ${mediaQuery.tablet}) {
    width: 100%;
    height: 300rem;
  }
`
const ContentWrapper = styled.div`
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  position: relative;
  height: calc(100% - 140rem);
  padding: 70rem 0;
  @media (max-width: ${mediaQuery.tablet}) {
    width: 100%;
    padding: 30rem 0;
    .smalHex {
      display: none;
    }
  }
`

const Title = styled.h3`
  color: ${colors.white};
  font-size: 42rem;
  z-index: 1;
  text-align: center;
  max-width: 470rem;
  padding: 0 20rem;
  line-height: 1.2;
  position: relative;
  @media (max-width: ${mediaQuery.laptop}) {
    font-size: ${typographySizes.m}rem;
  }
  @media (max-width: ${mediaQuery.tablet}) {
    padding: 60rem 20rem;
  }
`

const MidHex = styled.img`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: -1;
  @media (max-width: ${mediaQuery.tablet}) {
    width: 40%;
  }
`

const ImgTextSection = props => (
  <StyledMainWrapper id="entry">
    <ImgTextWrapper>
      <ImageWrapper />
      <ContentWrapper>
        <img
          className="smalHex"
          src={smalHex}
          data-sal="slide-up"
          data-sal-delay="00"
          data-sal-easing="ease"
          data-sal-duration="500"
        />
        <Title
          data-sal="slide-up"
          data-sal-delay="400"
          data-sal-easing="ease"
          data-sal-duration="500"
        >
          Prosta opowieść o{'\u00A0'}ryżu i{'\u00A0'}roślinach{' '}
          <MidHex src={biHex} />
        </Title>
        <div
          data-sal="slide-up"
          data-sal-delay="400"
          data-sal-easing="ease"
          data-sal-duration="500"
        >
          {' '}
          {/* <Button file={menuPdf} tertiary>
            pobierz menu w pdf
          </Button> */}
        </div>
      </ContentWrapper>
    </ImgTextWrapper>
  </StyledMainWrapper>
)

export default ImgTextSection
