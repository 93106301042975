import React, { useRef, useEffect, useState } from 'react'
import NavMenu from 'src/components/global/NavMenu.js'

import styled, { keyframes } from 'styled-components'

import { StaticImage } from 'gatsby-plugin-image'
import { graphql } from 'gatsby'

import { Link, animateScroll as scroll } from 'react-scroll'

import { Helmet } from 'react-helmet'

import colors from '../assets/styles/colors'
import mediaQuery from 'src/assets/styles/mediaQuery.js'
import typographySizes from 'src/assets/styles/typographySizes.js'

import HeaderArrowSVG from 'src/assets/svg/header-arrow.svg'

import MainWrapper from 'src/components/global/MainWrapper.js'
import Button from 'src/components/global/Button.js'

import ButtonOrder from 'src/components/global/ButtonOrder.js'
import BgLeaf from 'src/components/global/BgLeaf.js'
import ImgTextSection from '/src/components/global/ImgTextSection.js'
import MenuSection from '/src/components/global/MenuSection.js'
import AboutSection from 'src/components/global/AboutSection.js'
import PromotionsSection from 'src/components/global/PromotionsSection.js'
import OrderOnlineSection from 'src/components/global/OrderOnlineSection.js'
import ImgRowSection from 'src/components/global/ImgRowSection.js'
import Newsletter from 'src/components/global/Newsletter/Newsletter.js'

import fbImg from 'src/assets/images/fb.jpeg'
import favicon from 'src/assets/svg/favicon.svg'

import menuPDF from 'src/static/menu.pdf'

const Wrapper = styled.div`
  padding-top: 0rem;
`

const Upmenu = styled.div``

// markup
const Menu = () => {
  useEffect(() => {
    const isBrowser = typeof window !== 'undefined'

    if (isBrowser) {
      window.upmenuSettings = {
        id: 'a20dc498-7221-11ec-9695-525400080621',
      }
    }

    const script = document.createElement('script')

    script.src = 'https://cdn.upmenu.com/media/upmenu-widget.js'
    script.async = true
    script.defer = true

    document.body.appendChild(script)

    return () => {
      document.body.removeChild(script)
    }
  }, ['https://cdn.upmenu.com/media/upmenu-widget.js'])

  return (
    <>
      <Helmet
        htmlAttributes={{ lang: 'pl' }}
        titleTemplate={`Kimidori | WEGAŃSKIE SUSHI W DOSTAWIE`}
        title={`Kimidori | WEGAŃSKIE SUSHI W DOSTAWIE`}
      >
        <link rel="icon" type="image/svg+xml" href={favicon} />
        <meta
          name="description"
          content="wegańskie sushi dla miłośników minimalizmu i prostoty"
        />
        <meta name="image" content="../static/fb.jpeg" />
        <meta property="og:image" content={fbImg} />
        <meta property="og:url" content="kimidori.pl/" />
        <meta
          property="og:title"
          content="`WEGAŃSKIE SUSHI W DOSTAWIE| Kimidori`"
        />

        <meta
          property="og:description"
          content="wegańskie sushi dla miłośników minimalizmu i prostoty"
        />
      </Helmet>
      <NavMenu />

      <Wrapper>
        <Upmenu id="upmenu-widget"></Upmenu>
      </Wrapper>
    </>
  )
}

export default Menu
